import { createNavigation } from "next-intl/navigation";
import { NavigateOptions } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { useRouter as useToploaderRouter } from "nextjs-toploader/app";
import { routing } from "../i18n/routing";

type NavigateOptionsWithLocale = Partial<NavigateOptions> & {
  locale?: string;
};

const { Link, redirect, usePathname, useRouter: useIntlRouter } = createNavigation(routing);

export { Link, redirect, usePathname };

/**
 * Custom router hook that merges functionality of next-intl router and nextjs-toploader.
 *
 * We need both because:
 * - next-intl handles proper i18n routing and translation paths
 * - nextjs-toploader provides loading indicator during navigation
 *
 */
export function useRouter() {
  const intlRouter = useIntlRouter();
  const toploaderRouter = useToploaderRouter();

  return {
    ...intlRouter,
    push: async (href: string, options?: NavigateOptionsWithLocale) => {
      await Promise.all([toploaderRouter.push(href, options), intlRouter.push(href, options)]);
    },
    replace: async (href: string, options?: NavigateOptionsWithLocale) => {
      await Promise.all([
        toploaderRouter.replace(href, options),
        intlRouter.replace(href, options),
      ]);
    },
    refresh: async () => {
      await Promise.all([toploaderRouter.refresh(), intlRouter.refresh()]);
    },
    back: async () => {
      await Promise.all([toploaderRouter.back(), intlRouter.back()]);
    },
    forward: async () => {
      await Promise.all([toploaderRouter.forward(), intlRouter.forward()]);
    },
  };
}
