import enTranslations from "./lang/en";
import plTranslations from "./lang/pl";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const assertEqual = <A, B extends A, _C extends B>() => {};

assertEqual<typeof enTranslations, typeof plTranslations, typeof enTranslations>(); // if plTranslations is underscored in red -> it's missing a key from en. And vice versa when enTranslations is underscored.

export { TranslationWrapper } from "./TranslationContext";
export * from "./types";
export * from "./useTranslations";
