import { SupportedLanguages } from "@ea/shared_types/next/ea.enums";
import { defineRouting } from "next-intl/routing";

export const locales = [SupportedLanguages.EN.toLowerCase(), SupportedLanguages.PL.toLowerCase()];

export const localePrefix = "always";

export const routing = defineRouting({
  locales,
  defaultLocale: "en",
  localePrefix,
  localeDetection: false,
});
