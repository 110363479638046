"use client";
import { Route } from "next";
import {
  useParams as useNextParams,
  useSearchParams as useNextSearchParams,
} from "next/navigation";
import qs from "qs";
import { z } from "zod";

export const createRoutePath = <T extends string>(route: Route<T>) => route;

// Leaving for future
// Concanation of string in generics cast them to normal string which breaks routing
// It was a try to not require /(locale)/ in our routes
// import { Route as NextRoute } from "next";
// type RouteWrapper<T extends string> = [NextRoute<T>] extends [`${infer Return}`]
//   ? Return extends `/${string}/${infer Rest}`
//     ? `/${Rest}`
//     : Return
//   : NextRoute<T>;
// type Route<T extends string> = RouteWrapper<`/locale${T}`>;

type RouteWithParams<PathParams extends z.ZodSchema | undefined, RouteInferType extends string> = {
  params: PathParams;
  get: (
    params: z.input<PathParams extends undefined ? never : PathParams>,
  ) => Route<RouteInferType>;
};

type ZodResolveInput<T extends z.ZodSchema | undefined> = T extends z.ZodSchema
  ? z.input<T>
  : never;
type ZodResolveOutput<T extends z.ZodSchema | undefined> = T extends z.ZodSchema
  ? z.output<T>
  : never;

type NonParametrizedRoute = {
  (dynamicChunk?: string): string;
  useParams: () => Record<string, never>;
  useSearchParams: () => Record<string, never>;
  params: never;
  searchParams: never;
};
type NonParametrizedRouteWithQuery<QueryParams extends z.ZodSchema | undefined> = {
  (params: { query: ZodResolveInput<QueryParams> }, dynamicChunk?: string): string;
  useParams: () => Record<string, never>;
  useSearchParams: () => ZodResolveOutput<QueryParams>;
  params: never;
  searchParams: ZodResolveOutput<QueryParams>;
};
type ParametrizedRoute<PathParams extends z.ZodSchema | undefined> = {
  (params: { path: ZodResolveInput<PathParams> }, dynamicChunk?: string): string;
  useParams: () => ZodResolveOutput<PathParams>;
  useSearchParams: () => Record<string, never>;
  params: ZodResolveOutput<PathParams>;
  searchParams: never;
};
type ParametrizedRouteWithQuery<
  PathParams extends z.ZodSchema | undefined,
  QueryParams extends z.ZodSchema | undefined,
> = {
  (
    params: { path: ZodResolveInput<PathParams>; query: ZodResolveInput<QueryParams> },
    dynamicChunk?: string,
  ): string;
  useParams: () => ZodResolveOutput<PathParams>;
  useSearchParams: () => ZodResolveOutput<QueryParams>;
  params: ZodResolveOutput<PathParams>;
  searchParams: ZodResolveOutput<QueryParams>;
};

export function createRoute<
  RouteInferType extends string,
  // PathParams extends undefined,
  QueryParams extends z.ZodSchema,
>(path: Route<RouteInferType>, query: QueryParams): NonParametrizedRouteWithQuery<QueryParams>;
export function createRoute<RouteInferType extends string>(
  path: Route<RouteInferType>,
): NonParametrizedRoute;
export function createRoute<RouteInferType extends string, PathParams extends z.ZodSchema>(
  path: RouteWithParams<PathParams, RouteInferType>,
): ParametrizedRoute<PathParams>;
export function createRoute<
  RouteInferType extends string,
  PathParams extends z.ZodSchema,
  QueryParams extends z.ZodSchema,
>(
  path: RouteWithParams<PathParams, RouteInferType>,
  query: QueryParams,
): ParametrizedRouteWithQuery<PathParams, QueryParams>;
export function createRoute<
  RouteInferType extends string,
  PathParams extends z.ZodSchema,
  QueryParams extends z.ZodSchema,
>(
  path: RouteWithParams<PathParams, RouteInferType> | Route<RouteInferType>,
  querySchema?: QueryParams,
):
  | ParametrizedRouteWithQuery<PathParams, QueryParams>
  | NonParametrizedRouteWithQuery<QueryParams>
  | ParametrizedRoute<PathParams>
  | NonParametrizedRoute {
  const routeCreator = (
    params?: Partial<{
      path: ZodResolveInput<PathParams>;
      query: ZodResolveInput<QueryParams>;
    }>,
    dynamicChunk?: string,
  ) => {
    if (params?.path && typeof path === "object") {
      const res = path.params.safeParse(params.path);
      if (!res.success) {
        throw new Error(`Invalid route params: ${res.error.message}`);
      }
    }
    if (params?.query) {
      if (!querySchema) {
        throw new Error(`Route doesn't have query schema and you try to pass query params`);
      }
      const res = querySchema.safeParse(params?.query);
      if (!res.success) {
        throw new Error(`Invalid search params: ${res.error.message}`);
      }
    }
    const baseUrl = typeof path === "object" ? path.get(params?.path) : path;
    // next-intl doesnt require locale in the path
    const baseUrlWithoutLocale = baseUrl.replace("/(locale)", "");
    const searchString = params?.query && qs.stringify(params?.query);
    return [
      baseUrlWithoutLocale + (dynamicChunk ? `/${dynamicChunk}` : ""),
      searchString ? `?${searchString}` : "",
    ].join("");
  };

  routeCreator.useParams = function useParams() {
    const schema = typeof path === "object" ? path.params : z.any();
    const params = useNextParams();
    const res = schema.safeParse(params);
    if (!res.success) {
      throw new Error(
        `Invalid route params for route: ${res.error.message}: ${JSON.stringify(params)}`,
      );
    }
    return res.data;
  };

  routeCreator.useSearchParams = function useSearchParams() {
    const schema = querySchema || z.any();
    const data = qs.parse(useNextSearchParams().toString());
    const res = schema.safeParse(data);
    if (!res.success) {
      throw new Error(`Invalid search params for route: ${res.error.message}`);
    }
    return res.data;
  };

  // // set the type
  // routeCreator.params = undefined as z.output<Params>;
  // set the runtime getter
  Object.defineProperty(routeCreator, "params", {
    get() {
      throw new Error(
        "Route params is only for type usage, not runtime. Use it like `typeof Route.params`",
      );
    },
  });

  // eslint-disable-next-line
  return routeCreator as any;
}
